import React from 'react';

export default function Logo({
  color = '#161615',
  bigText = false,
  fillColor = 'none',
  subText = false,
  subTextColor = '#161615',
  style = {},
  ...props
}) {
  let viewBox;
  if (bigText) {
    if (subText) {
      viewBox = '75 -20 1200.4 400.42';
    } else {
      viewBox = '75 7 1200.4 339.42';
    }
  } else {
    viewBox = '50 7 1000.4 339.42';
  }

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      style={{ ...style, transform: style.transform || (subText ? 'scale(1.180)' : '') }}
    >
      <defs>
        <style>{`
          .cls-full-1, .cls-full-2 {
            fill: none;
            stroke-width: 2.83px;
          }

          .cls-full-1 {
            stroke-miterlimit: 10;
          }

          .cls-full-2 {
            stroke-linecap: round;
            stroke-linejoin: round;
          }

          .big-full #text {
            transform: translate(0,-32%) scale(1.4);
          }

          .subtext.big-full #text {
            transform: translate(-2px,-27%) scale(1.4);
          }

          .big-full .cls-full-2 {
            stroke-width: 3.96px;
          }

          .big-full #veggies {
            transform: translateX(262px);
          }

          .cls-full-text {
            font-family: JosefinSlab, sans-serif;
            font-weight: 300;
            letter-spacing: 0.01em;
          }
      `}</style>
      </defs>
      <g
        id="wortel"
        className={`${bigText ? 'big-full' : ''} ${subText ? 'subtext' : 'no-subtext'}`}
        style={{ stroke: color }}
      >
        <g id="veggies">
          <path
            className="cls-full-2 cls-full-4"
            style={{ fill: fillColor }}
            d="M790.65,271.57c-1,.37-21.78,9.69-24,29.21s9.62,39.8,42,39.8c45.23,0,49.51-27.7,49.52-34.37,0-17.07-12.68-33.56-31.63-33.89,23-26.14,59.13-123.19,59.13-123.19S850.1,155.44,834,201.92s4.59,55.84,24.67,52.4c27-1.95,51.95-82.59,27-105.19"
          />
          <path
            className="cls-full-2"
            d="M826.29,271.62c-1.42-3-39.5-110.16-39.5-110.16s-18.09,16-6.73,52.48c10.43,26.71,27.74,32.21,35.66,28.64,13.39-6,14.13-26.37,11.13-42.48C818,155.8,787,161.46,787,161.46"
          />
          <path
            className="cls-full-2"
            d="M922.61,178.3,888,25.58s7.52-14.35,28.58-5.87c3.64,11.36,13.58,58.07,21.92,92.06"
          />
          <path
            className="cls-full-2"
            d="M930,75.8c-.29-1.13.39-55.95-.22-56.4s14.37-15.24,31.5,0c-1.57,13.43-2.11,22.45-2.11,22.45"
          />
          <polyline className="cls-full-2" points="948.58 178.29 952.48 271.22 1050.11 271.22" />
          <path
            className="cls-full-2"
            d="M943.76,196.4c2.81-11.68,33.65-142.65,35.82-149.51-9.32-6.33-16-6.62-25.18-2.72C951.45,57,922.61,179.08,922.61,179.08v92.14"
          />
          <line className="cls-full-2" x1="722.12" y1="252.37" x2="741.79" y2="252.37" />
          <line className="cls-full-2" x1="706.08" y1="259.43" x2="743.03" y2="259.43" />
          <g>
            <path
              className="cls-full-2"
              d="M680.47,271.29s0-44.55,33.63-44.55c0,0,29.88-1.87,29.88,44.55l46.91,0"
            />
            <path
              className="cls-full-2"
              d="M714.42,224.87s.52-102.83,71.27-168c12.21,11.5-2.74,25.92-13.9,29-4,1.13.56,9.33-6.71,17.72C758,112,751.27,109,748.85,112.9s-.75,16-6.16,22.77S732.69,126.81,714.42,224.87Z"
            />
            <path
              className="cls-full-2"
              d="M714.89,224.36s-8-48.53-2.43-60.12,17.63-11.9,15.47-29.57c-1.63-14.79-18.46-6.22-10.23-19.67s3.56-26-6.55-23.32c-.85.23,1-15.28-15.46-13.84s-12.61,18-12.61,18c-10.11,2.72-12.77,13.62-1.33,24.47s-3.2,10.6-1.93,20.52c2.7,18.76,12.31,15.53,20.74,25.26S714,224.87,714,224.87"
            />
          </g>
          <line className="cls-full-2" x1="827.25" y1="271.62" x2="922.52" y2="271.22" />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M643.77,272.7h36.7a1.42,1.42,0,0,0,0-2.84H641.94Z"
          />
        </g>
        <g id="text">
          <line className="cls-full-1" x1="494.48" y1="271.34" x2="624.02" y2="271.34" />
          <line className="cls-full-1" x1="353.27" y1="271.31" x2="464.66" y2="271.33" />
          <line className="cls-full-1" x1="77.4" y1="271.3" x2="289.85" y2="271.32" />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M102.1,205.18V272H98.32V262a23.13,23.13,0,1,1,0-25.06v-31.8Zm-3.78,44.37A19.45,19.45,0,1,0,78.92,269,19.45,19.45,0,0,0,98.32,249.55Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M155.19,255.84l3.32,1.26a23.33,23.33,0,0,1-44.46-2.16,24.32,24.32,0,0,1-.72-5.66,23.13,23.13,0,0,1,46-3.68c.09.54.09.9.18,1.26l-41.68,7.45a19.34,19.34,0,0,0,18.68,14.38A19.92,19.92,0,0,0,155.19,255.84Zm-38.09-7.19c0,.72.09,1.44.09,2.25l38-6.74a19.65,19.65,0,0,0-18.87-14.55A19.27,19.27,0,0,0,117.1,248.65Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M202,215.42v23.8h19.49V243H202v25.25h34.32V272H198.22V211.64h38.09v3.78Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M276.55,229.16,275,232.75a15.09,15.09,0,0,0-8.44-2.78,14.86,14.86,0,0,0-14.91,15.09V272H247.9V227.09h3.77v7.64a18.15,18.15,0,0,1,15.27-8.35A17.88,17.88,0,0,1,276.55,229.16Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M357,245v27h-3.77V245.06a14.92,14.92,0,1,0-29.83,0v27.68h-3.77V245.06a14.91,14.91,0,1,0-29.82,0V272h-3.78V227.09h3.78v7.64a18.15,18.15,0,0,1,15.27-8.35,18.38,18.38,0,0,1,16.71,10.78A18.44,18.44,0,0,1,357,245Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M408.6,255.84l3.33,1.26a23.33,23.33,0,0,1-44.47-2.16,24.32,24.32,0,0,1-.72-5.66,23.14,23.14,0,0,1,46-3.68c.09.54.09.9.18,1.26l-41.69,7.45a19.35,19.35,0,0,0,18.69,14.38A19.91,19.91,0,0,0,408.6,255.84Zm-38.08-7.19c0,.72.09,1.44.09,2.25l38-6.74a19.64,19.64,0,0,0-18.86-14.55A19.26,19.26,0,0,0,370.52,248.65Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M451.36,229.16l-1.52,3.59a15.12,15.12,0,0,0-8.45-2.78,14.86,14.86,0,0,0-14.91,15.09V272h-3.77V227.09h3.77v7.64a18.16,18.16,0,0,1,15.27-8.35A17.85,17.85,0,0,1,451.36,229.16Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M498.26,245.15V272h-3.78V245.15a14.91,14.91,0,1,0-29.82-.09V272h-3.77V205.18h3.77v29.64a18,18,0,0,1,15.27-8.44C490.08,226.38,498.26,234.91,498.26,245.15Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M507.87,249.55A23.18,23.18,0,1,1,531,272.73,23.11,23.11,0,0,1,507.87,249.55Zm42.67,0A19.41,19.41,0,1,0,531.13,269,19.54,19.54,0,0,0,550.54,249.55Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M603.72,255.84,607,257.1a23.33,23.33,0,0,1-44.46-2.16,24.32,24.32,0,0,1-.72-5.66,23.13,23.13,0,0,1,46-3.68c.09.54.09.9.18,1.26l-41.68,7.45A19.34,19.34,0,0,0,585,268.69,19.92,19.92,0,0,0,603.72,255.84Zm-38.09-7.19c0,.72.09,1.44.09,2.25l38-6.74a19.65,19.65,0,0,0-18.87-14.55A19.26,19.26,0,0,0,565.63,248.65Z"
          />
          <path
            className="cls-full-3"
            style={{ fill: color }}
            d="M631.12,246.77,647.65,272h-4.31l-14.65-22.37L624,255.12v16.8h-3.77V205.09H624v44.73l19.05-22.73h4.58Z"
          />
        </g>
        {subText && (
          <text
            className="cls-full-text"
            x={bigText ? '199.37px' : '149.37px'}
            y={bigText ? '370.44px' : '339.44px'}
            fontSize={bigText ? '70px' : '49px'}
            stroke={subTextColor}
            fill={subTextColor}
          >
            GASTENVERBLIJF
          </text>
        )}
      </g>
    </svg>
  );
}
