import React, { useState } from 'react';
import { imageUrlFor, useImageSize } from '../lib/image-url';

const Image = ({ image, className, style, aspectRatio, ...props }) => {
  const dimensions = image?.asset?.metadata?.dimensions;
  const ratio = aspectRatio || dimensions?.aspectRatio || 16 / 9;

  const imageSize = useImageSize(ratio, dimensions && [dimensions.width, dimensions.height]);
  const [isLoaded, setIsLoadedState] = useState(false);
  const setIsLoaded = () => setIsLoadedState(false);

  let img = null;
  if (image?.asset) {
    img = (
      <figure
        {...props}
        className={`bg-white ${className || ''} bg-cover bg-no-repeat relative`}
        style={{
          backgroundImage: !isLoaded ? `url(${image.asset.metadata.lqip})` : '',
          paddingTop: `${Math.round((1 / ratio) * 100 * 100) / 100}%`,
          ...style,
        }}
      >
        <img
          className={`w-full transition-opacity duration-200 ease-in-out absolute top-0 left-0 ${
            isLoaded ? 'opacity-100' : ''
          }`}
          src={imageUrlFor(image)
            .size(...imageSize)
            .auto('format')
            .url()}
          ref={(imgElem) => {
            if (!imgElem) {
              return;
            }

            imgElem.onload = setIsLoaded;
            if (imgElem.complete) {
              setIsLoaded();
            }
          }}
          alt={image.alt}
        />
        <figcaption>{image.caption}</figcaption>
      </figure>
    );
  }
  return img;
};

export default Image;
