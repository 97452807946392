import { Link } from 'gatsby';
import React from 'react';

const Footer = ({ siteTitle }) => (
  <footer className="bg-white">
    {/*<div className="container mx-auto  px-8">*/}
    {/*  <div className="w-full flex flex-col md:flex-row py-6">*/}
    {/*    <div className="flex-1 mb-6">*/}
    {/*      <a*/}
    {/*        className="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"*/}
    {/*        href="#"*/}
    {/*      >*/}
    {/*        <svg*/}
    {/*          className="h-8 fill-current inline"*/}
    {/*          xmlns="http://www.w3.org/2000/svg"*/}
    {/*          viewBox="0 0 512.005 512.005"*/}
    {/*        >*/}
    {/*          <rect*/}
    {/*            fill="#2a2a31"*/}
    {/*            x="16.539"*/}
    {/*            y="425.626"*/}
    {/*            width="479.767"*/}
    {/*            height="50.502"*/}
    {/*            transform="matrix(1,0,0,1,0,0)"*/}
    {/*            fill="rgb(0,0,0)"*/}
    {/*          />*/}
    {/*          <path*/}
    {/*            className="plane-take-off"*/}
    {/*            d=" M 510.7 189.151 C 505.271 168.95 484.565 156.956 464.365 162.385 L 330.156 198.367 L 155.924 35.878 L 107.19 49.008 L 211.729 230.183 L 86.232 263.767 L 36.614 224.754 L 0 234.603 L 45.957 314.27 L 65.274 347.727 L 105.802 336.869 L 240.011 300.886 L 349.726 271.469 L 483.935 235.486 C 504.134 230.057 516.129 209.352 510.7 189.151 Z "*/}
    {/*          />*/}
    {/*        </svg>{' '}*/}
    {/*        {siteTitle}*/}
    {/*      </a>*/}
    {/*    </div>*/}

    {/*    <div className="flex-1">*/}
    {/*      <p className="uppercase md:mb-6">Links</p>*/}
    {/*      <ul className="list-reset mb-6">*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <a href="https://www.tailwindtoolbox.com/templates/landing-page">*/}
    {/*            <span className="hover:underline hover:text-orange-500">*/}
    {/*              Page theme*/}
    {/*            </span>*/}
    {/*          </a>*/}
    {/*        </li>*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <a*/}
    {/*            href="https://sanity.io/docs"*/}
    {/*            className="no-underline hover:underline hover:text-orange-500"*/}
    {/*          >*/}
    {/*            Sanity Help*/}
    {/*          </a>*/}
    {/*        </li>*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <a*/}
    {/*            href="https://slack.sanity.io"*/}
    {/*            className="no-underline hover:underline hover:text-orange-500"*/}
    {/*          >*/}
    {/*            Slack Community*/}
    {/*          </a>*/}
    {/*        </li>*/}
    {/*      </ul>*/}
    {/*    </div>*/}
    {/*    <div className="flex-1">*/}
    {/*      <p className="uppercase md:mb-6">Legal</p>*/}
    {/*      <ul className="list-reset mb-6">*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <span className="no-underline hover:underline hover:text-orange-500">*/}
    {/*            Terms*/}
    {/*          </span>*/}
    {/*        </li>*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <span className="no-underline hover:underline hover:text-orange-500">*/}
    {/*            Privacy*/}
    {/*          </span>*/}
    {/*        </li>*/}
    {/*      </ul>*/}
    {/*    </div>*/}
    {/*    <div className="flex-1">*/}
    {/*      <p className="uppercase md:mb-6">Social</p>*/}
    {/*      <ul className="list-reset mb-6">*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <a*/}
    {/*            href="https://twitter.com/sanity_io"*/}
    {/*            className="no-underline hover:underline hover:text-orange-500"*/}
    {/*          >*/}
    {/*            Twitter*/}
    {/*          </a>*/}
    {/*        </li>*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <a*/}
    {/*            href="https://www.linkedin.com/company/sanity-io/"*/}
    {/*            className="no-underline hover:underline hover:text-orange-500"*/}
    {/*          >*/}
    {/*            Linkedin*/}
    {/*          </a>*/}
    {/*        </li>*/}
    {/*      </ul>*/}
    {/*    </div>*/}
    {/*    <div className="flex-1">*/}
    {/*      <p className="uppercase md:mb-6">Company</p>*/}
    {/*      <ul className="list-reset mb-6">*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <Link*/}
    {/*            to="/blog"*/}
    {/*            className="no-underline hover:underline hover:text-orange-500"*/}
    {/*          >*/}
    {/*            Blog*/}
    {/*          </Link>*/}
    {/*        </li>*/}
    {/*        <li className="mt-2 inline-block mr-2 md:block md:mr-0">*/}
    {/*          <a*/}
    {/*            href="https://www.sanity.io/contact"*/}
    {/*            className="no-underline hover:underline hover:text-orange-500"*/}
    {/*          >*/}
    {/*            Contact*/}
    {/*          </a>*/}
    {/*        </li>*/}
    {/*      </ul>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
  </footer>
);

export default Footer;
