export const COLORS = {
  orange: '#F2B082',
  orangeL: '#FCE6D3',
  orangeH: '#C27730',
  green: '#B3C9BC',
  greenL: '#DBE5D6',
  greenH: '#3A5C41',
  purple: '#B9546E',
  purpleL: '#D58C90',
  purpleH: '#883453',
};
