import clientConfig from '../../client-config';
import imageUrlBuilder from '@sanity/image-url';
import { useEffect, useState } from 'react';
import { window, exists } from 'browser-monads';

const builder = imageUrlBuilder(clientConfig.sanity);

export function imageUrlFor(source) {
  return builder.image(source);
}

export const getSizeForAspectRatio = (aspectRatio, [maxWidth, maxHeight] = [], width) => {
  const targetWidth = maxWidth ? Math.min(width, maxWidth) : width;
  const targetHeight = Math.round(width / aspectRatio);
  if (targetHeight > maxHeight) {
    return [Math.round(maxHeight * aspectRatio), maxHeight];
  }
  return [targetWidth, targetHeight];
};

export const getImageSizeForWindow = (aspectRatio, maxDimensions) => {
  const getSize = getSizeForAspectRatio.bind(null, aspectRatio, maxDimensions);

  if (!exists(window)) {
    return getSize(1080);
  }
  const width = window.innerWidth;
  if (width > 1440) {
    return getSize(1440);
  } else if (width > 1280) {
    return getSize(1080);
  }
  return getSize(720);
};

export const useImageSize = (aspectRatio, maxDimensions) => {
  const [imageSize, setImageSize] = useState(getImageSizeForWindow(aspectRatio, maxDimensions));

  useEffect(() => {
    if (!window) {
      return;
    }

    const handleResize = () => {
      const newSize = getImageSizeForWindow(aspectRatio, maxDimensions);
      if (newSize[0] > imageSize[0]) {
        setImageSize(newSize);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return imageSize;
};
