import React from 'react';
import { Link, navigate } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Image from './Image';

export const doNavigate = (target) => {
  if (!target || !target.length) {
    return;
  }
  const internal = /^\/(?!\/)/.test(target);
  if (internal) {
    navigate(target);
  } else {
    window.open(target, '_blank', 'noopener noreferrer');
  }
};

const PathLink = ({ external, path, ...props }) =>
  external ? (
    <OutboundLink
      href={path}
      target="_blank"
      rel="noopener noreferrer"
      className={`${className}`}
      {...props}
    />
  ) : (
    <Link to={path.startsWith('/') ? path : `/${path}`} {...props} />
  );

const CTALink = ({
  route,
  link,
  kind,
  landingPageRoute,
  title,
  className = '',
  style = {},
  illustration = {},
}) => {
  let path = route || link || '#';
  if (landingPageRoute && landingPageRoute.slug && landingPageRoute.slug.current) {
    path = landingPageRoute.slug.current;
  }

  if (kind === 'button') {
    return (
      <div className="my-4 md:my-6 px-10 md:px-8">
        {illustration && !illustration.disabled && illustration.image && (
          <PathLink external={!!link} path={path}>
            <Image
              image={illustration.image}
              aspectRatio={1}
              className="px-1 transform md:hover:scale-102 transition-scale ease-linear duration-100 delay-100"
              style={{ marginBottom: '-1em' }}
            />
          </PathLink>
        )}
        <button
          id="navAction"
          onClick={() => doNavigate(path)}
          className={`bg-orange font-wavehaus font-semibold text-white text-xl hover:underline rounded py-1 px-4 relative z-10 ${className}`}
        >
          {title}
        </button>
      </div>
    );
  }

  // External
  if (link) {
    return (
      <PathLink external={!!link} path={path} style={style}>
        {title}
      </PathLink>
    );
  }

  return (
    <PathLink external={!!link} path={path} className={`text-orange ${className}`} style={style}>
      {title}
    </PathLink>
  );
};

export default CTALink;
