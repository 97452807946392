import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Turn as Hamburger } from 'hamburger-react';
import CTALink from './CTALink';
import LogoSquare from './logo/square_carrot';
import LogoFull from './logo/full';
import { COLORS } from '../styles/constants';

const Header = ({ showNav, scrolled, navMenuItems = [], textWhite = true }) => {
  const [navOpen, setNavOpen] = useState(false);

  let headerClass = 'fixed w-full z-30 top-0 text-orange bg-orange-l text-xl h-14 md:h-16';
  headerClass += scrolled ? ' md:shadow' : '';

  const navActionClass =
    'mx-auto lg:mx-0 hover:underline fonts-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 bg-orange-l';

  let navContentClass =
    'w-full flex-grow md:flex md:items-center md:w-auto text-black p-4 md:p-0 z-20 py-2 md:h-16 bg-orange-l ';
  navContentClass += !navOpen ? ' hidden md:block' : '';

  const titleClass = 'toggleColour no-underline hover:no-underline text-xl';

  const showSubText = !navOpen && !scrolled;
  return (
    <nav id="header" className={headerClass}>
      <div className="w-full h-full lg:container lg:mx-auto flex flex-wrap flex-row items-center justify-between my:pt-1">
        <div
          className="md:hidden w-full h-full flex mx-auto flex-col items-center justify-center w-full cursor-pointer md:cursor-default select-none"
          onClick={() => setNavOpen(!navOpen)}
        >
          <div className="flex mx-auto flex-row w-full h-full">
            <div
              className="flex-grow border-b border-orange-dark h-full"
              style={{ borderBottomWidth: '0.1rem' }}
            />
            <LogoFull
              className="h-full z-30 pt-1 flex-none box-content"
              color={COLORS.orangeH}
              fillColor={scrolled ? 'none' : COLORS.orangeL}
              style={{
                paddingTop: '8px',
                maxWidth: '211px',
                transform: 'scale(1.38)',
                flexGrow: 10,
              }}
              subTextColor={showSubText ? '#161615' : 'none'}
              subText
              bigText
            />
            <div
              className="flex-grow border-b border-orange-dark h-full"
              style={{ borderBottomWidth: '0.1rem' }}
            />
          </div>
          <div className="h-0 self-end relative" style={{ bottom: '52px', right: '1rem' }}>
            <Hamburger
              toggled={navOpen}
              toggle={setNavOpen}
              direction="left"
              color={COLORS.orangeH}
              rounded
              label="Open menu"
              size={29}
            />
          </div>
        </div>

        {showNav && navMenuItems && (
          <div className={navContentClass} id="nav-content">
            <ul className="list-reset md:flex pl-5 md:pl-0 justify-around flex-1 items-center text-2xl">
              <li
                id="home-menu-item"
                className="flex-1 text-orange md:text-center"
                style={{ flexGrow: '0.5' }}
              >
                <div
                  className={`md:pl-4 flex items-center hidden md:block absolute top-0 mt-2px h-0 ${
                    !scrolled ? 'md:opacity-0 duration-300' : 'duration-500  delay-100'
                  } transition-opacity ease-in-out`}
                >
                  <Link id="siteTitle" className={titleClass} to="/">
                    <LogoSquare className="h-16 py-1" color={COLORS.orangeH} />
                  </Link>
                </div>
                <CTALink
                  route="/"
                  title="home"
                  className={`inline-block ${
                    scrolled ? 'md:opacity-0 duration-300' : 'duration-500 delay-100'
                  } transition-opacity ease-in-out`}
                  buttonActionClass={navActionClass}
                  style={{ minWidth: '119px' }}
                />
              </li>
              {navMenuItems
                .filter((props) => !props.disabled)
                .map((itemProps, i, arr) => (
                  <li
                    style={i + 1 === arr.length ? { flexGrow: '0.5' } : {}}
                    className="flex-1 text-orange md:text-center lowercase"
                    key={`__menuItem-${i}`}
                  >
                    <CTALink {...itemProps} buttonActionClass={navActionClass} />
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
