import React from 'react';

export default function Logo({ color = '#161615', stroke = 5.1, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 100 641.33 400.33">
      <defs>
        <style>{`
          .cls-sqr-2, .cls-sqr-3, .cls-sqr-4, .cls-sqr-5 {
            fill: none;
          }

          .cls-sqr-2, .cls-sqr-3, .cls-sqr-5 {
            stroke-linecap: round;
            stroke-linejoin: round;
          }

          .cls-sqr-1 {
            stroke-width: ${stroke / 5}px;
          }

          .cls-sqr-2 {
            stroke-width: ${stroke}px;
          }

          .cls-sqr-3 {
            stroke-width: ${stroke}px;
          }

          .cls-sqr-4 {
            stroke-miterlimit: 10;
          }

          .cls-sqr-4, .cls-sqr-5 {
            stroke-width: ${stroke-2}px;
          }
          `}</style>
      </defs>
      <g id="Vierkant_1" data-name="Vierkant 1" style={{ stroke: color, fill: color }}>
        <polygon
          className="cls-sqr-4"
          points="449.38 480.89 315.8 480.89 312.56 475.6 451.75 475.61 449.38 480.89"
        />
        <line className="cls-sqr-2" x1="520.17" y1="446.81" x2="553.43" y2="446.81" />
        <line className="cls-sqr-2" x1="493.07" y1="458.73" x2="555.51" y2="458.73" />
        <g>
          <path
            className="cls-sqr-3"
            d="M449.8,478.24s0-75.24,56.82-75.24c0,0,50.57-3.69,50.57,74.77"
          />
          <path
            className="cls-sqr-2"
            d="M507.17,399.83s.87-173.78,120.44-284c20.65,19.44-4.62,43.82-23.48,49.07-6.81,1.9.94,15.77-11.35,30-12,14.19-23.33,9.14-27.42,15.76s-1.27,27.08-10.41,38.48S538,234.11,507.17,399.83Z"
          />
          <path
            className="cls-sqr-2"
            d="M508,399s-13.56-82-4.09-101.6,29.79-20.11,26.14-50c-2.75-25-31.19-10.5-17.3-33.24s6-44-11.06-39.4c-1.44.38,1.61-25.84-26.13-23.4s-21.31,30.35-21.31,30.35c-17.08,4.6-21.58,23-2.24,41.36s-5.41,17.92-3.28,34.69c4.57,31.7,20.81,26.24,35.06,42.68s22.75,99.41,22.75,99.41"
          />
        </g>
        <path
          className="cls-sqr-1"
          d="M84.85,129.81v113H78.47V225.91a39.1,39.1,0,1,1,0-42.36V129.81Zm-6.38,75a32.87,32.87,0,1,0-32.79,32.95A32.89,32.89,0,0,0,78.47,204.8Z"
        />
        <path
          className="cls-sqr-1"
          d="M174.58,215.43l5.61,2.13c-5,13.51-19,26-37.19,25.8a39.73,39.73,0,0,1-38-29.45,41.19,41.19,0,0,1-1.21-9.56,39.1,39.1,0,0,1,77.73-6.23c.15.91.15,1.52.3,2.13l-70.44,12.6A32.67,32.67,0,0,0,143,237.14,33.66,33.66,0,0,0,174.58,215.43Zm-64.37-12.15c0,1.22.15,2.43.15,3.8l64.22-11.39a33.21,33.21,0,0,0-31.89-24.59A32.55,32.55,0,0,0,110.21,203.28Z"
        />
        <path
          className="cls-sqr-1"
          d="M69.34,433.38v45.39H63V433.38a25.2,25.2,0,1,0-50.4-.15v47.66H6.18V365.82h6.38v50.1a30.49,30.49,0,0,1,25.81-14.27C55.52,401.65,69.34,416.07,69.34,433.38Z"
        />
        <path
          className="cls-sqr-1"
          d="M85.59,440.82A39.17,39.17,0,1,1,124.76,480,39.07,39.07,0,0,1,85.59,440.82Zm72.11,0a32.79,32.79,0,1,0-32.79,32.94A33,33,0,0,0,157.7,440.82Z"
        />
        <path
          className="cls-sqr-1"
          d="M247.58,451.45l5.62,2.12c-5,13.51-19,26-37.2,25.81a39.72,39.72,0,0,1-37.95-29.45,41.29,41.29,0,0,1-1.22-9.57,39.1,39.1,0,0,1,77.73-6.22c.16.91.16,1.52.31,2.12l-70.45,12.6A32.69,32.69,0,0,0,216,473.16,33.67,33.67,0,0,0,247.58,451.45ZM183.21,439.3c0,1.22.15,2.43.15,3.8l64.22-11.39a33.2,33.2,0,0,0-31.88-24.59A32.55,32.55,0,0,0,183.21,439.3Z"
        />
        <path
          className="cls-sqr-1"
          d="M293.89,436.11l27.93,42.66h-7.28L289.79,441l-7.89,9.26v28.39h-6.38V365.67h6.38v75.6l32.18-38.41h7.74Z"
        />
        <g>
          <path
            className="cls-sqr-1"
            d="M12.56,261.65v40.23H45.5v6.38H12.56v42.66h58v6.37H6.18v-102H70.55v6.38Z"
          />
          <path
            className="cls-sqr-1"
            d="M138.57,284.88,136,291a25.41,25.41,0,0,0-14.27-4.71,25.11,25.11,0,0,0-25.2,25.51v45.54H90.14V281.38h6.38v12.91a30.71,30.71,0,0,1,25.81-14.12A30.25,30.25,0,0,1,138.57,284.88Z"
          />
          <path
            className="cls-sqr-1"
            d="M274.6,311.6v45.69h-6.37V311.75a25.21,25.21,0,1,0-50.41,0v45.54h-6.37V311.75a25.21,25.21,0,1,0-50.41,0v45.54h-6.37V281.38H161v12.91a30.72,30.72,0,0,1,25.81-14.12,31,31,0,0,1,28.24,18.22,31.08,31.08,0,0,1,28.54-18.22C260.94,280.17,274.6,294.14,274.6,311.6Z"
          />
          <path
            className="cls-sqr-1"
            d="M361.75,330l5.62,2.12c-5,13.51-19,26-37.2,25.81a39.73,39.73,0,0,1-37.95-29.45,41.28,41.28,0,0,1-1.21-9.57,39.1,39.1,0,0,1,77.73-6.22c.15.91.15,1.52.3,2.12l-70.44,12.6a32.67,32.67,0,0,0,31.57,24.3A33.67,33.67,0,0,0,361.75,330Zm-64.37-12.15c0,1.21.15,2.43.15,3.8l64.22-11.39a33.19,33.19,0,0,0-31.88-24.59A32.56,32.56,0,0,0,297.38,317.82Z"
          />
          <path
            className="cls-sqr-1"
            d="M434,284.88,431.44,291a25.41,25.41,0,0,0-14.27-4.71A25.11,25.11,0,0,0,392,311.75v45.54h-6.38V281.38H392v12.91a30.69,30.69,0,0,1,25.8-14.12A30.25,30.25,0,0,1,434,284.88Z"
          />
        </g>
        <line className="cls-sqr-4" x1="62.96" y1="478.23" x2="281.9" y2="478.25" />
        <line className="cls-sqr-5" x1="609.27" y1="478.24" x2="557.87" y2="478.24" />
      </g>
    </svg>
  );
}
